import { pageTag } from "shared/messages";
import { getUWQuestionsLink } from "shared/helper";

import "./UWQuestions.scss";

export const UWQuestionsText = "UW Questions";

const UWQuestions = ({
  lob,
  userType,
  segmentCd,
  searchData,
  reportData,
  hukModalRef,
}) => {
  return (
    <>
      {lob.shortCode === "WC" && reportData?.commonWCCodes.length > 0 && (
        <button
          className="col d-print-none text-start uw_questions_button"
          onClick={() => hukModalRef.current?.present()}
          aria-label={`Underwriting question for ${lob.shortCode}`}
          data-dl={`{"event_id": "${UWQuestionsText} - modal", "da_track": "true"}`}
        >
          {UWQuestionsText}
        </button>
      )}
      {(lob.shortCode === "SPEC" || lob.shortCode === "CA") && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="col-md d-print-none uw_questions_container my-md-0 my-1"
          aria-label={`Underwriting question for ${lob.shortCode}`}
          href={getUWQuestionsLink(
            lob.shortCode,
            userType,
            searchData.stateCd,
            segmentCd,
            searchData.classCode,
            searchData.descriptorCd,
            searchData.descriptorText
          )}
          data-dl={`{"event_parent": "${pageTag}", "event_type": "Link Click", "event_id": "${UWQuestionsText}", "event_value": "${lob.text}", "da_track": "true"}`}
        >
          {UWQuestionsText}
        </a>
      )}
    </>
  );
};

export default UWQuestions;
