import React from "react";
import { HukPopover } from "huk-react-bindings";

import { setClassCodeDescriptionText, getWCCodes } from "../shared/shared";
import AlternativeMarketPlan from "../AlternativeMarketPlan/AlternativeMarketPlan";

import "./ClassDetailsInternal.scss";

/**
 * Creates  Cyber Hazard text based on the hazard code
 * @param {string} hazardCode hazard code value from getSearchResults response
 * @return {string}  Cyber Hazard text or "--"
 */
export const createCyberHazardText = (hazardCode) => {
  if (hazardCode === "L") {
    return "Low";
  } else if (hazardCode === "M") {
    return "Medium";
  } else if (hazardCode === "H") {
    return "High";
  } else {
    return "--";
  }
};

const ClassDetailsInternal = ({ searchData, reportData }) => {
  const { exposureBaseName, marketGroupName, naicscode, altrntMarketPlanText } =
    searchData || {};

  const wcCodes = getWCCodes(reportData);

  return (
    <div className="class_details_internal_container mt-5">
      <div className="class_details_title">
        <h2 className="mb-0">Class Details</h2>
      </div>
      <div className="container rounded-3 class_details_and_amp_card">
        <div
          className={`row p-4 class_details_card ${
            !altrntMarketPlanText ? "class_details_card_border" : ""
          }`}
        >
          <div className="col-6 column_border">
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">
                Exposure Basis
              </div>
              <div className="col-sm fw-medium">{exposureBaseName}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">
                Common WC Codes
              </div>
              <div className="col-sm fw-medium class_details_wc_codes">
                {" "}
                {Object.keys(wcCodes)?.length > 0
                  ? Object.keys(wcCodes).map((item, index) => (
                      <div key={index} className="pop_over">
                        <HukPopover
                          buttontext={item}
                          type="tooltip-link"
                          icon="attention-alt"
                          popovertitle="Description"
                          data-testid="HukPopoverId"
                        >
                          {setClassCodeDescriptionText(item, wcCodes)}
                        </HukPopover>
                        {index !== Object.keys(wcCodes).length - 1 ? (
                          <p>, </p>
                        ) : null}
                      </div>
                    ))
                  : "--"}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">
                Auditable Class
              </div>
              <div className="col-sm fw-medium">
                {searchData?.auditableIndicator === "Y" ? "Yes" : "No"}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">Company View</div>
              <div className="col-sm fw-medium">
                {searchData?.companyViewOnlyIndicatorCd === "Y" ? "Yes" : "No"}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">Program</div>
              <div className="col-sm fw-medium">
                {searchData?.programCode === "--" &&
                searchData?.programName === "--"
                  ? "--"
                  : `${searchData?.programCode} - ${searchData?.programName}`}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 class_details_header">Cyber Hazard</div>
              <div className="col-sm fw-medium">
                {createCyberHazardText(searchData?.dbhazardCode)}
              </div>
            </div>
          </div>
          <div className="col-6 ps-3">
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">Market Group</div>
              <div className="col-sm fw-medium">{marketGroupName}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">SIC</div>
              <div className="col-sm fw-medium">
                {searchData?.siccode + " - " + searchData?.sicdescription}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">NAICS</div>
              <div className="col-sm fw-medium">{naicscode}</div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4 class_details_header">
                ADM Eligibility
              </div>
              <div className="col-sm fw-medium">
                {searchData?.businessClassCdTypeName === "SPCTRM" && (
                  <div className="row">
                    <div className="col-3 col-lg-2 fw-medium">BOP</div>
                    <div className="col fw-medium adm_text">
                      {searchData?.specProcessingRuleCd}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-3 col-lg-2 fw-medium">WC</div>
                  <div className="col fw-medium adm_text">
                    {searchData?.wcprocessingRuleCd}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 col-lg-2 fw-medium">A</div>
                  <div className="col fw-medium adm_text">
                    {searchData?.caprocessingRuleCd}
                  </div>
                </div>
                {searchData?.businessClassCdTypeName === "SPCTRM" && (
                  <div className="row">
                    <div className="col-3 col-lg-2 fw-medium">ML</div>
                    <div className="col fw-medium adm_text">
                      {searchData?.mlprocessingRuleCode}
                    </div>
                  </div>
                )}
                {searchData?.businessClassCdTypeName === "SPCTRM" && (
                  <div className="row">
                    <div className="col-3 col-lg-2 fw-medium">EO</div>
                    <div className="col fw-medium adm_text">
                      {searchData?.scplprocessingRuleCode}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {altrntMarketPlanText && (
          <AlternativeMarketPlan searchData={searchData} />
        )}
      </div>
    </div>
  );
};

export default ClassDetailsInternal;
