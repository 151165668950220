import { HukButton } from "huk-react-bindings";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getAppEnv } from "env/env";
import { getProducerContactsByUserIdURL } from "shared/apiEndpoints";

import { axiosPostInstance } from "../../../../shared/axiosInstance";

import "./UWInfo.scss";

const UWInfo = ({
  marketGroupCd,
  producerCode,
  classCode,
  businessClassTypeName,
}) => {
  const [searchParams] = useSearchParams();
  const startICONQuote = "Get An ICON Quote";
  const userID = sessionStorage.getItem("ebcUID");
  const [uwInfoRes, setUWInfoRes] = useState(null);
  const newBusinessUW = "New Business Underwriter";
  const newConstructionUW = "Construction Underwriter NB";
  const navigatedFromICON = document.referrer.includes("esubmissions");
  const newTechUW = "Technology and Life Science New Business Underwriter";

  const buildUWContactInfoObj = (uwRes) => {
    let uwType = newBusinessUW;
    if (marketGroupCd === "CT") {
      uwType = newConstructionUW;
    }
    if (marketGroupCd === "TM" || marketGroupCd === "TE") {
      uwType = newTechUW;
    }

    if (!uwRes) {
      return {};
    }
    const uwInfo = uwRes.find((obj) => obj.role === uwType);
    return {
      name: `${uwInfo.firstname} ${uwInfo.lastname}`,
      email: uwInfo.mail,
      phone: uwInfo.phone,
    };
  };

  useEffect(() => {
    axiosPostInstance()
      .post(getProducerContactsByUserIdURL, { userId: userID })
      .then((res) => {
        setUWInfoRes(buildUWContactInfoObj(res.data.data));
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  /**
   * Opens the ICON new business quote in a new window
   */
  const handleIconClick = () => {
    window.open(
      `${
        getAppEnv().ICON_DOMAIN
      }&spClass=${classCode}&riskState=${searchParams.get(
        "RiskState"
      )}&clsType=${businessClassTypeName}&cpid=&prodCd=${producerCode}`,
      "_blank"
    );
  };

  return (
    <div
      className="mb-5 mb-md-0 pb-3 d-print-none uw_info_container"
      data-testid="UWInfoID"
    >
      {!navigatedFromICON && (
        <div className="pt-4 pt-md-0 quote_button">
          <HukButton
            type="button"
            iconclass="quote"
            hukclass="btn-primary"
            text={startICONQuote}
            data-testid="IconQuoteID"
            data-dl={`{"da_track": "true", "event_id": "${startICONQuote}"}`}
            onClick={() => {
              handleIconClick();
            }}
          />
        </div>
      )}
      <div className={`${navigatedFromICON ? "pt-4" : ""} uw_contact_text`}>
        <p className="questions_text">Questions?</p>
        <p>
          <span>Contact your underwriter:</span>
          <br />
          <span>{uwInfoRes?.name}</span>
          {uwInfoRes?.email && (
            <>
              <br />
              <a
                href={`mailto:
               ${uwInfoRes?.email}?subject=Appetite Guide Question - ${classCode} `}
                className="uw_email_link"
              >
                {uwInfoRes?.email}
              </a>
            </>
          )}
          {uwInfoRes?.phone && (
            <>
              <br />
              <span className="phone_text">{uwInfoRes?.phone}</span>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default UWInfo;
