export const isoLOB = [
  {
    text: "Workers' Compensation",
    appetiteCode: "wctaldappetiteCd",
    hazardCd: "wchazardCd",
    uWIndexCd: "",
    shortCode: "WC",
  },
  {
    text: "Commercial Auto",
    appetiteCode: "cataldappetiteCd",
    uWIndexCd: "",
    hazardCd: "cahazardCd",
    shortCode: "CA",
  },
  {
    text: "General Liability",
    appetiteCode: "gltaldaappetiteCode",
    uWIndexCd: "",
    hazardCd: "glhazardCd",
    shortCode: "GL",
  },
  {
    text: "Commercial Property",
    appetiteCode: "propertyTALDAppetiteCode",
    uWIndexCd: "",
    hazardCd: "propHazardCd",
    shortCode: "PROP",
  },
];

export const spectrumLOB = [
  {
    text: "Spectrum (BOP)",
    appetiteCode: "specTALDAppetiteCd",
    hazardCd: "specHazardCd",
    uWIndexCd: "specUNDRWritingIndexCd",
    shortCode: "SPEC",
  },
  {
    text: "Spectrum Umbrella",
    appetiteCode: "specTALDAppetiteCd",
    hazardCd: "N/A - UMB",
    shortCode: "UMB",
    uWIndexCd: "umbundrwritingIndexCd",
  },
  {
    text: "Workers' Compensation",
    appetiteCode: "wctaldappetiteCd",
    hazardCd: "wchazardCd",
    uWIndexCd: "wcundrwritingIndexCd",
    shortCode: "WC",
  },
  {
    text: "Commercial Auto",
    appetiteCode: "cataldappetiteCd",
    hazardCd: "cahazardCd",
    uWIndexCd: "caundrwritingIndexCd",
    shortCode: "CA",
  },
  {
    text: (
      <>
        Management Liability
        {/* wbr tag used to break after FID on tablet sized screens */}
        <div className="fw-normal">
          D&O/EPL/FID
          <wbr />
          /Crime
        </div>
      </>
    ),
    appetiteCode: "mltaldappetiteCode",
    hazardCd: "mlhazardCode",
    uWIndexCd: "",
    shortCode: "ML",
  },
  {
    text: (
      <>
        Professional Liability
        <div className="fw-normal">E&O Standalone</div>
      </>
    ),
    appetiteCode: "scpltaldaappetiteCode",
    hazardCd: "scplhazardCode",
    uWIndexCd: "",
    shortCode: "SC_PL",
  },
];
