import { getAppEnv } from "env/env";

export const getSearchResultsURL = `${
  getAppEnv().BASIC_API_URL
}/getsearchresults`;

export const getReportDataURL = `${getAppEnv().BASIC_API_URL}/getreportdata`;

export const getAddressCleansedURL = `${
  getAppEnv().BASIC_API_URL
}/getAddressCleansed`;

export const getSmartClassURL = `${getAppEnv().BASIC_API_URL}/getSmartClass`;

export const getEnhancedAppetiteURL = `${getAppEnv().BASIC_API_URL}/appetite`;

export const getDefaultProducerCodeURL = `${
  getAppEnv().BASIC_API_URL
}/getDefaultProducerCd`;

export const getCPIDURL = `${getAppEnv().BASIC_API_URL}/cpid`;

export const getProducerContactsByUserIdURL = `${
  getAppEnv().BASIC_API_URL
}/getProducerContactsByUserId`;

export const agentProfileURL = `${getAppEnv().BASIC_API_URL}/agentprofile`;
