import { getAppEnv } from "env/env";
import { formatNumber } from "shared/helper";

import MaxLimitRow from "./MaxLimitRow";

import "./MaxLimitsTable.scss";

const MaxLimitsTable = ({ searchData }) => {
  /**
   * Set content based on payroll per Account or Sales Per Location\Account has a value of 0 or !==0
   * @return {JSX}
   */
  const checkForSalesOrPayroll = () => {
    if (
      searchData?.salesPerLocationMax !== "0" &&
      searchData?.salesPerAccountMax !== "0"
    ) {
      return (
        <>
          <MaxLimitRow
            description={"Sales per location"}
            data={formatNumber(searchData?.salesPerLocationMax)}
          />
          <MaxLimitRow
            description={"Sales per account"}
            data={formatNumber(searchData?.salesPerAccountMax)}
          />
        </>
      );
    } else if (searchData?.payrollPerAccountMax !== "0") {
      return (
        <MaxLimitRow
          description={"Payroll per account"}
          data={formatNumber(searchData?.payrollPerAccountMax)}
        />
      );
    }
  };

  return (
    <div className="max_limits_container my-5 ">
      <div className="small_commercial_title">
        <h2>Small Commercial Maximum Threshold</h2>
      </div>
      <div className="container-fluid mt-3">
        <div className="row blue_font_color table_data">
          <div className="col-sm-5 col-md-6 col-lg-4 d-none d-sm-block">
            Business Line
          </div>
          <div className="col-sm d-none d-sm-block">Maximum Threshold</div>
          <hr className="blue_border mb-0" />
        </div>
        <div className="row">
          {/* Column which holds Business Line & Maximum Limits */}
          <div className="col-md-12 col-lg-8 my-2 my-sm-0 pt-2">
            <div className="row">
              <div className="col-sm">
                <span className="blue_font_color table_data">
                  BOP - Spectrum
                </span>
                <div className="sub_line_text">Monoline or Account</div>
              </div>
              <div className="col-sm-8 my-2 my-sm-0">
                <MaxLimitRow
                  description={"Property per location"}
                  data={formatNumber(searchData?.propertyPerLocationMax)}
                />
                <MaxLimitRow
                  description={"Property per account"}
                  data={formatNumber(searchData?.propertyPerAccountMax)}
                />
                <div>{checkForSalesOrPayroll()}</div>
              </div>
            </div>
            <hr className="my-2" />
            <div className="row">
              <div className="col-sm table_data blue_font_color">
                Workers' Compensation
                <div className="sub_line_text">Monoline or Account</div>
              </div>
              <div className="col-sm-8 my-2 my-sm-0">
                <MaxLimitRow
                  description={"Payroll per account"}
                  data={formatNumber(searchData?.wcpayrollPerAccountMax)}
                />
              </div>
            </div>
            <hr className="my-2" />
            <div className="row">
              <div className="col-sm table_data blue_font_color">
                Commercial Automobile
                <div className="sub_line_text">
                  Monoline Only (no limit if writing Spectrum/WC)
                </div>
              </div>
              <div className="col-sm-8 my-2 my-sm-0">
                <MaxLimitRow
                  description={"Auto power units"}
                  data={
                    searchData?.businessClassCdTypeName === "ISO_CGL"
                      ? "--"
                      : searchData?.monoLineAutoNumberOfVehicles
                  }
                />
              </div>
            </div>
            <hr className="my-2" />
            <div className="row">
              <div className="col-sm table_data blue_font_color">
                Professional Liability
                <div className="sub_line_text">E&O Standalone</div>
              </div>
              <div className="col-sm-8 my-2 my-sm-0">
                <MaxLimitRow
                  description={"Total gross revenue"}
                  data={formatNumber(searchData?.totalGrossRevenueMax)}
                />
              </div>
            </div>
            <hr className="my-2" />
            <div className="row mb-2">
              <div className="col-sm table_data blue_font_color">
                Management Liability
                {/* wbr tag used to break after FID on tablet sized screens */}
                <div className="sub_line_text">
                  (D&O/EPL/FID
                  <wbr />
                  /Crime)
                </div>
              </div>
              <div className="col-sm-8 my-2 my-sm-0">
                <MaxLimitRow
                  description={"Total gross revenue"}
                  data={formatNumber(searchData?.mltotalGrossRevenueMax)}
                />
              </div>
            </div>
          </div>
          <hr className="blue_border d-block d-md-none mb-0" />
          {/* Column holds "Exceed These Limits" text */}
          <div className="col-md ms-lg-5 py-3 px-sm-5 text-center exceed_limits_column">
            <p className="mt-xxl-3 exceed_limits_style">
              Exceed These Thresholds?
            </p>
            <p className="lh-sm exceed_limits_text">
              If your customer exceeds these limits, reach out to your Middle &
              Large Commercial Underwriter or
              <br />
              <span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className=" lh-sm contactlink"
                  data-dl={`{"da_track": "true"}`}
                  href={`${getAppEnv().EBC_DOMAIN}/contacts`}
                >
                  The Hartford Contacts
                </a>
              </span>
            </p>
          </div>
          <hr className="blue_border d-none d-md-block" />
        </div>
      </div>
    </div>
  );
};

export default MaxLimitsTable;
