import React, { useEffect, useState } from "react";
import { HukRadio } from "huk-react-bindings";
import { useSelector, useDispatch } from "react-redux";

import { options } from "shared/globalData";
import { agentProfileURL } from "shared/apiEndpoints";
import { axiosPostInstance } from "shared/axiosInstance";
import { changeSearchBy } from "redux/actions/searchByActions";
import { searchReset } from "redux/actions/searchResultsActions";
import { businessSearch, requiredFieldErrorMessage } from "shared/messages";

import { updateWCSearchType } from "./helper";
import SpectrumSearch from "./SpectrumSearch/SpectrumSearch";
import BusinessSearch from "./BusinessSearch/BusinessSearch";

import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import "./SpectrumClassSearch.scss";

const SpectrumClassSearch = ({ activeTabID }) => {
  const dispatch = useDispatch();
  const { riskState } = useSelector((state) => state.riskStateReducer);
  const { selectedOption } = useSelector((state) => state.searchByReducer);

  // below are only used for business search pilot and can be removed when pilot is over
  const [newOptions, setNewOptions] = useState(options);
  const [loading, setLoading] = useState(true);

  /**
   * Utilized to show/hide business search based on agent profile authorization code 34
   * This can be removed when the pilot for business search ends
   */
  useEffect(() => {
    // Service only needs to be called for external users
    if (sessionStorage.getItem("userType") === "external") {
      // only call service on initial load to prevent unnecessary calls on reloads/returns to the page
      if (sessionStorage.getItem("pilot") === null) {
        axiosPostInstance()
          .post(agentProfileURL, { userId: sessionStorage.getItem("ebcUID") })
          .then((res) => {
            const data = res?.data;
            const pilot =
              data?.unifiedProfile?.agencyEmployee?.authorizationLevels?.includes(
                34
              ) ||
              data?.unifiedProfile?.agencyBranchInfo?.authorizationLevels?.includes(
                34
              );

            sessionStorage.setItem("pilot", pilot);
            addBusinessSearch();
            // need to setLoading at each level due to asynchronous nature of the API call, cannot just set it in addBusinessSearch()
            // if we do not do this the business search will pop up delayed after loading which is bad UX
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        addBusinessSearch();
        setLoading(false);
        return;
      }
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Add's business search radio btn if UID has auth code 34
   * This can be removed when the pilot for business search ends
   */
  const addBusinessSearch = () => {
    if (sessionStorage.getItem("pilot") === "true") {
      const businessSearchOption = {
        name: "Business search",
        value: "Business search",
        searchType: "CLASS_CODE_SEARCH_EQUAL",
        dynamicField: {
          validationMessage: `${requiredFieldErrorMessage}`,
        },
      };

      const newArray = [...options];
      newArray.splice(1, 0, businessSearchOption); // Insert at the second position
      setNewOptions(newArray);
    }
  };

  /**
   * Updates redux store with the selected radio button value
   * @param {object} event - Radio button custom event object
   */
  const handleOptionChange = (event) => {
    dispatch(searchReset());

    // update the WC validation/helper text for PA and DE
    if (event.target.checkedOption.name === "Workers' Comp code") {
      dispatch(changeSearchBy(updateWCSearchType(riskState)));
      return;
    }

    dispatch(changeSearchBy(event.target.checkedOption));
  };

  /**
   * this if loading statement is only necessary for the business search pilot and can be removed when pilot is completed
   * we need to wait until agent profile service call is complete to determine if business search should be shown
   * if this is not here business search radio btn will be delayed appearing on the page which is bad UX
   */
  if (loading) {
    return <LoadingIndicator text="Loading..." />;
  } else {
    return (
      <div
        data-testid="spectrumClassSearchID"
        className="spectrum_class_search_container container mt-3"
      >
        <div className="row">
          <div className="col-md-5 col-lg-4 col-xl-3">
            <div className="d-flex">
              <div>
                <HukRadio
                  required
                  options={newOptions} // when pilot is over utilize 'options' from globalData
                  grouplabel="Search By"
                  onHukInput={handleOptionChange}
                  data-testid="SearchByRadioID"
                  data-dl={`{"da_track": "true"}`}
                />
              </div>

              {/* vertical dividing line between radio buttons and inputs on desktop */}
              <div className="d-none d-md-block spectrum_vertical_divider"></div>
            </div>
          </div>

          {/* horizontal dividing line between radio buttons and inputs on desktop */}
          <div className="d-block d-md-none my-3 dividing_line_bottom_mobile"></div>

          {selectedOption !== businessSearch ? (
            <SpectrumSearch activeTabID={activeTabID} />
          ) : (
            <BusinessSearch activeTabID={activeTabID} />
          )}
        </div>
      </div>
    );
  }
};

export default SpectrumClassSearch;
